import { AbstractMesh, Matrix, Scene, Vector2 } from '../babylonjs-import';
import { ClipPlane } from '../ClipPlane';
import { LazyResizableRawTexture } from '../LazyResizableRawTexture';
import {
    BIT_SHIFTING,
    createPropertiesTextureReadShaderCode,
    DEPTH_WRITE_IGNORES_VISIBLITY_PROPERTY,
    IOS_BIT_OPERATIONS,
    PROPERTIES_CASTED_A,
    PROPERTIES_SAMPLER,
    REQUIRES_WORLD_FRAGMENT,
    VISIBLITY_PROPERTY,
    WEBGL2
} from '../ShaderSnippets';
import { ShortFrustumProjectionMatrixState } from '../ShortFrustumProjectionMatrixState';
import {
    clipPlanesStrings,
    CullingMaterial,
    DIFFUSE_SAMPLER,
    SIZE,
    UniformsAndDefinesAndSamplers
} from './CullingMaterial';

export const DISCARD_DITHER_TRANSPARENT = 'discardDitherTransparent';
export const IS_BABYLON_MESH = 'isBabylonMesh';
export const BABYLON_MESH_ALPHA = 'babylonMeshAlpha';

const WORLD = 'world';
const VIEW_PROJECTION = 'viewProjection';
const WORLD_VIEW_PROJECTION = 'worldViewProjection_custom';
const DEPTH_VALUES = 'depthValues';
const NONLINEARDEPTH = 'NONLINEARDEPTH';
const vertexShaderSource = /* glsl */ `
#ifdef GL_ES
    precision highp float;
#endif
attribute vec3 position;
attribute vec2 uv;

${BIT_SHIFTING}

#ifdef WEBGL2
    out float vColorPixelIndex;
    out vec2 vUV;
#else
    varying float vColorPixelIndex;
    varying vec2 vUV;
#endif
#ifdef ${REQUIRES_WORLD_FRAGMENT}
    #ifdef WEBGL2
        out vec3 vPositionW;
    #else
        varying vec3 vPositionW;
    #endif
    uniform mat4 ${WORLD};
    uniform mat4 ${VIEW_PROJECTION};
#else
    uniform mat4 ${WORLD_VIEW_PROJECTION};
#endif 

#ifndef ${NONLINEARDEPTH}
    uniform vec2 ${DEPTH_VALUES};
    #ifdef WEBGL2
        out float vDepthMetric;
    #else
        varying float vDepthMetric;
    #endif
#endif

uniform vec3 ${SIZE};

#define CUSTOM_VERTEX_DEFINITIONS
void main(void) {
    #ifdef ${REQUIRES_WORLD_FRAGMENT}
        vec4 worldPos = ${WORLD} * vec4(position, 1.0);
        vPositionW = worldPos.xyz;
        gl_Position = ${VIEW_PROJECTION} * worldPos;
    #else
        gl_Position = ${WORLD_VIEW_PROJECTION} * vec4(position, 1.0);
    #endif
    
    #ifndef ${NONLINEARDEPTH}
        vDepthMetric = ((gl_Position.z+${DEPTH_VALUES}.x)/(${DEPTH_VALUES}.y));
    #endif
    
    vColorPixelIndex = uv.x;
    vUV = uv;
}
`;

const fragmentShaderSource = /*glsl*/ `
    #ifdef GL_ES
        precision highp float;
    #endif
    #ifdef WEBGL2
        in float vColorPixelIndex;
        in vec2 vUV;
    #else
        varying float vColorPixelIndex;
        varying vec2 vUV;
    #endif
    #ifndef ${NONLINEARDEPTH}
        #ifdef WEBGL2
            in float vDepthMetric;
        #else
            varying float vDepthMetric;
        #endif
    #endif

    #ifdef ${REQUIRES_WORLD_FRAGMENT}
        #ifdef WEBGL2
            in vec3 vPositionW;
        #else
            varying vec3 vPositionW;
        #endif
    #endif 
    uniform sampler2D ${DIFFUSE_SAMPLER};
    #ifdef ${IS_BABYLON_MESH}
        uniform float babylonMeshAlpha;
    #else
        uniform sampler2D ${PROPERTIES_SAMPLER};
    #endif
    ${BIT_SHIFTING}
    uniform vec3 ${SIZE};
    ${clipPlanesStrings.CLIP_PLANE_UNIFORMS}
    
    void main(void) {
        
        ivec2 textureSize = textureSize(${DIFFUSE_SAMPLER}, 0);
        float colorPixelIndex = vColorPixelIndex + 0.5;
        float uvY = floor(colorPixelIndex / float(textureSize.x)); 
        float uvX = floor(floor(colorPixelIndex) - uvY * float(textureSize.x));    
        vec2 pixelIndex = vec2(uvX, uvY);

        #ifdef ${DISCARD_DITHER_TRANSPARENT}
            float alpha = texelFetch(${DIFFUSE_SAMPLER}, ivec2(pixelIndex), 0).a;

            #ifndef ${IS_BABYLON_MESH}
                if (abs(alpha - 1.0) > 0.0001) {
                    discard;
                }
            #endif
        #endif

        #ifdef ${IS_BABYLON_MESH}
            if(babylonMeshAlpha < 0.001) {
                discard;
            }
        #else
            ${createPropertiesTextureReadShaderCode('pixelIndex')};
            ${PROPERTIES_CASTED_A}
            ${DEPTH_WRITE_IGNORES_VISIBLITY_PROPERTY}
            ${VISIBLITY_PROPERTY}
            float uvAlpha = 0.0;

            if (!visible && !depthWriteIgnoresVisibility) {
                discard;
            }
        #endif
            
        #ifdef ${REQUIRES_WORLD_FRAGMENT}
            vec3 worldSpaceVertex = vPositionW;
            ${clipPlanesStrings.CLIP_PLANE_DISCARDING};
        #endif
        vec4 depthOutput = vec4(0.0);
        #ifdef ${NONLINEARDEPTH}
            depthOutput = vec4(gl_FragCoord.z, 0.0, 0.0, 0.0);
        #else
            depthOutput = vec4(vDepthMetric, 0.0, 0.0, 0.0);
        #endif

        #ifdef ${WEBGL2}
            glFragColor = depthOutput;
        #else
            gl_FragColor = depthOutput;
        #endif
    }
`;

/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

export abstract class CustomDepthMaterial extends CullingMaterial {
    public storeNonLinearDepth = true;

    private _requiresWorldSpace: boolean;
    private _shortFrustumProjectionMatrixState: ShortFrustumProjectionMatrixState;

    public getFragmentSource(): string {
        const fragmentSource = fragmentShaderSource;

        return fragmentSource;
    }

    public getVertexSource(): string {
        const vertexSource = vertexShaderSource;
        return vertexSource;
    }

    constructor(
        name: string,
        scene: Scene,
        public readonly diffuseTexture: LazyResizableRawTexture,
        public readonly propertiesTexture?: LazyResizableRawTexture
    ) {
        super(
            name,
            scene,
            {
                attributes: ['position', 'uv'],
                samplers: [DIFFUSE_SAMPLER, PROPERTIES_SAMPLER],
                defines: [],
                uniforms: []
            },
            false,
            true
        );

        this._excludeIgnoreDepthWriteCuttingPlanes = true;
        this._writeColorBlending = false;

        if (!scene.activeCamera)
            throw Error('Scene must have a active camera otherwise the custom depth material cannot be created.');
        else {
            this._shortFrustumProjectionMatrixState = scene.activeCamera.twinfinity.shortFrustum;
        }
    }

    getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const uniformsDefinesSamplers = super.getUniformsAndDefinesAndSamplers();

        const defines: string[] = [IOS_BIT_OPERATIONS]; // Make some check if we use something that is incompatible with WebGL 2.0 here

        const uniforms = [DEPTH_VALUES, SIZE];

        const clipPlanes = [...ClipPlane.entries(this.visualSettings.ifc.clipPlanes)];

        this._requiresWorldSpace = false;
        for (let i = 0; i < clipPlanes.length; i++) {
            if (clipPlanes[i][1].enabled) {
                this._requiresWorldSpace = true;
            }
        }

        if (this._requiresWorldSpace) {
            uniforms.push(WORLD, VIEW_PROJECTION);
            defines.push(REQUIRES_WORLD_FRAGMENT);
        } else {
            uniforms.push(WORLD_VIEW_PROJECTION);
        }

        if (this.storeNonLinearDepth) {
            defines.push(NONLINEARDEPTH);
        }

        const samplers = [DIFFUSE_SAMPLER];

        return {
            uniforms: [...uniformsDefinesSamplers.uniforms, ...uniforms],
            defines: [...uniformsDefinesSamplers.defines, ...defines],
            samplers: [...uniformsDefinesSamplers.samplers, ...samplers]
        };
    }

    public setLinearDepthMode(storeNonLinearDepth: boolean): void {
        this.storeNonLinearDepth = storeNonLinearDepth;
    }

    override bindFunction(mesh: AbstractMesh): void {
        super.bindFunction(mesh);
        const scene = this.getScene();
        const combinedMatrix = new Matrix();
        const effect = this.getEffect();

        effect.setVector2(
            DEPTH_VALUES,
            new Vector2(
                this._shortFrustumProjectionMatrixState.depthRenderCameraMinZ,
                this._shortFrustumProjectionMatrixState.depthRenderCameraMaxZ
            )
        );

        const camera = scene.activeCamera;
        combinedMatrix.reset();
        if (camera) {
            const meshWorldMatrix = mesh.getWorldMatrix();

            if (this._requiresWorldSpace) {
                camera
                    .getViewMatrix()
                    .multiplyToRef(this._shortFrustumProjectionMatrixState.getProjectionMatrix(), combinedMatrix);

                effect.setMatrix(WORLD, meshWorldMatrix);
                effect.setMatrix(VIEW_PROJECTION, combinedMatrix);
            } else {
                meshWorldMatrix.multiplyToRef(camera.getViewMatrix(true), combinedMatrix);
                combinedMatrix.multiplyToRef(
                    this._shortFrustumProjectionMatrixState.getProjectionMatrix(),
                    combinedMatrix
                );

                effect.setMatrix(WORLD_VIEW_PROJECTION, combinedMatrix);
            }
        } else {
            throw new Error('No camera?');
        }
    }
}
