﻿import { BimCamera } from './BimCamera';
import { BimContainer } from './loader/bim-api-client';
import { BimVertexDataRepository } from './loader/bim-vertex-data-repository';
import { BimIfcLoader, IfcWithOptions } from './loader/bim-ifc-loader';
import { BimPropertySetRepository } from './loader/bim-property-set-repository';
import { Materials } from './loader/materials';
import { TwinfinityViewer, GridOptions, RunRenderLoopHandler } from './loader/twinfinity-viewer';
import { Intersection } from './loader/bim-format-types';
import { BimProductAndMesh } from './loader/BimProductAndMesh';
import { BimIfcObject, BimIfcObjectForEachAction, BimIfcObjectForEachPredicate } from './loader/bim-ifc-object';
import { setMax, setMin, Vertex3 } from './math';
import { Vector3, Ray, Mesh, Color3 } from './loader/babylonjs-import';
import { CoordinateTracker } from './tools/CoordinateTracker';
import { BimApiLoadOptions } from './BimApiLoadOptions';
import { PickOptionType, Selectables } from './loader/Selectables';
import { telemetry } from './Telemetry';
import { BimCoreApiClient } from './loader/client/BimCoreApiClient';
import { PickResultType } from './loader/PickResult';
import { PredefinedPointerButtonId } from './PointerInfoWithTimings';
import { ColoredHighlightIndexes } from './loader/CustomBabylonMaterials/IfcMaterial';
import { isFailure } from './fail';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

/**
 * Click event handler
 * @deprecated
 */
export interface ClickEventHandler {
    (object?: BimProductAndMesh, hitInfo?: Intersection[], mesh?: Mesh): void;
}

/**
 * @deprecated Use `Color3` or `Color4` instead.
 */
type Color = [number, number, number];

export type BimIfcChangeLoader = BimIfcLoader;

const pointerDragThresholdTimeInMs = 200;

/**
 * BimCoreApi is a generic API class. It is completely unaware about the Twinfinity API backend
 * and can therefore be used as a base for integration with a completely different backend. This must be used
 * in conjunction with a specialized implementation of {@link BimCoreApiClient}. {@link BimApi} is an actual
 * example of such an implementation.
 */
export class BimCoreApi {
    private readonly _bimVertexDataRepository: BimVertexDataRepository;
    private readonly _bimIfc: BimIfcLoader;

    /**
     * Extension point for applications that need to run the renderloop outside their own
     * change handling (AngularJs is one such example). Assignment should be done before creation of
     * a {@link BimCoreApi} instance.
     * @example Example for Angular JS
     * ```typescript
     *  const bimApi: BimApi;
     *  BimCoreApi.runRenderLoop = this.ngZone.runOutSideAngular;
     * ```
     *  @beta
     * @param renderLoop Renderloop function will be provided by the viewer. Simply call it in your provided function
     */
    public static runRenderLoop: RunRenderLoopHandler = (renderLoop: () => void) => {
        renderLoop();
    };

    /**
     * Exposes viewer internals. Use this to access BabylonJS API.
     * Disclaimer! Accessing BabylonJS internals is considered advanced usage and you are effectively bypassing {@link BimCoreApi} (and subclasses).
     * We cannot make guarantees that:
     *  * BabylonJS APIs will remain backwards compatible. Therefore, your application may break when BabylonJS is updated to a new version.
     *  * Babylonjs data that {@link BimCoreApi} creates, such as scene hierarchy, lights, camera etc., will remain backwards compatible between versions. Relying on this may break your application.
     *  * Your modifications to BabylonJS data (such as scene hierarchy, lights, cameras etc.) will not be changed by code in this package.
     *  * Other bugs will not occur as a result of direct usage of BabylonJS APIs.
     * {@link https://doc.babylonjs.com/api/classes/babylon.scene}
     * @example
     * ```typescript
     * api.viewer.scene.ambientColor = new Color3(1, 0, 0);
     * ```
     */
    public readonly viewer: TwinfinityViewer;

    /**
     * @async
     * Creates a new instance of {@link BimCoreApi} or subclass of {@link BimCoreApi}.
     * @param containerId ID of the HTML element where the viewer should be created.
     * @param absoluteContainerUrlOrApiClient Absolute URL to the container.
     * @example
     * ```typescript
     * // Create an API instance.
     * const bimCoreApi = await BimCoreApi.create('viewer', canvas => new BimCoreApi(canvas, new BimApiClientTwinfinity()));
     * const bimApi = await BimCoreApi.create('viewer', canvas => new BimApi(canvas, new BimApiClientTwinfinity()));
     * ```
     */
    public static async createApi<TBimApi extends BimCoreApi>(
        _htmlElementOrId: string | HTMLElement,
        apiFactory: (canvas: HTMLCanvasElement) => TBimApi
    ): Promise<TBimApi> {
        // TODO Add Jquery PEP to DOM for touch?
        if (typeof _htmlElementOrId === 'string') {
            const domElement = document.getElementById(_htmlElementOrId);
            if (domElement === undefined) {
                throw new Error(`DOM canvas with id='${_htmlElementOrId}' does not exist`);
            }
            _htmlElementOrId = domElement!;
        }

        if (!(_htmlElementOrId instanceof HTMLCanvasElement)) {
            const canvas = document.createElement('canvas');
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            _htmlElementOrId.appendChild(canvas);
            _htmlElementOrId = canvas;
        }

        const api = apiFactory(_htmlElementOrId as HTMLCanvasElement);
        await api.initialize();
        return api;
    }

    /** Constructor. Use {@link createApi} instead. */
    public constructor(htmlCanvasElement: HTMLCanvasElement, private readonly _bimBackendApi: BimCoreApiClient) {
        telemetry.setAuthenticatedUserContext(telemetry.userId, _bimBackendApi.id);
        const material = new Materials();
        this.viewer = TwinfinityViewer.create(htmlCanvasElement, material, BimCoreApi.runRenderLoop);
        this.camera = new BimCamera(this.viewer, this);
        this._bimVertexDataRepository = new BimVertexDataRepository(_bimBackendApi);
        this._bimIfc = new BimIfcLoader(
            _bimBackendApi,
            new BimPropertySetRepository(_bimBackendApi),
            this._bimVertexDataRepository,
            material
        );
    }

    /**
     * @internal Returns an IFC structure.
     */
    public get ifc(): BimIfcChangeLoader {
        return this._bimIfc;
    }

    /** Direct access to the methods for communicating with the backend. */
    public get backend(): BimCoreApiClient {
        return this._bimBackendApi;
    }

    /** Exposes methods related to selection operations.
     *  For example, what the user actually clicked on.
     */
    public get selectables(): Selectables {
        return this.viewer.selectables;
    }

    /**
     * Subscribe to get access to pointer events (mouse, touchpad etc) and to determine
     * what (if anything) a mouse click interacted with (what object was 'picked' in the 3D scene).
     */
    public get onPointerObservable(): TwinfinityViewer['onPointerObservable'] {
        return this.viewer.onPointerObservable;
    }

    /**
     * @async
     * Sets the container of the current BimApi instance.
     * @param containerOrUrl Container object or absolute URL Example: https://bim.foo.com/sites/portal/projects/\{project name\}
     * @param options Loading options
     * @return Deprecated {@link BimQuery} instance. Do not use it. Use {@link foreach}, {@link product} {@link filter} instead
     */
    public async setContainer(containerOrUrl: BimContainer | URL, options?: BimApiLoadOptions): Promise<void> {
        this.clear();
        if (options === 'skip-ifc-load') {
            return;
        }
        let ifcChanges: IfcWithOptions[] = [];
        let loadPropertySets = false;
        let loadGeometry = false;
        if (!options) {
            ifcChanges = await this._bimBackendApi.getIfcChanges(containerOrUrl);
        } else {
            if ('ifcChangePredicate' in options) {
                ifcChanges = (await this._bimBackendApi.getIfcChanges(containerOrUrl)).filter((ifcChange) =>
                    options.ifcChangePredicate(ifcChange)
                );
                if (options.throwOnNoIfcChanges && ifcChanges.length === 0) {
                    throw new Error('options.ifcChangePredicate gave empty collection of BimIfcChange');
                }
                loadPropertySets = options.loadPropertySets;
                loadGeometry = options.loadGeometry;
            } else if ('ifcChanges' in options) {
                ifcChanges = options.ifcChanges
                    .filter((c) => c.load)
                    .map((c) => {
                        const { transform, change } = c;
                        return { ...change, ...{ transform } };
                    });
                loadPropertySets = options.loadPropertySets;
                loadGeometry = options.loadGeometry;
            } else {
                throw new Error('Not a supported options object');
            }

            for (const ifc of ifcChanges) {
                ifc.loadOptions = options;
            }
        }

        telemetry.trackTrace({ message: 'Load ifcchanges....' });
        const loaderElementResult = await this.ifc.add(ifcChanges);
        const event = telemetry.startTrackEvent('Loaded ifcchanges');
        for (const lEr of loaderElementResult) {
            if (isFailure(lEr)) {
                telemetry.trackTrace(
                    {
                        message: `Failed to load IFC.`,
                        severityLevel: SeverityLevel.Error
                    },
                    lEr
                );
                continue;
            }
        }
        event.stop();

        // If no IFC's have been loaded we get a default region bounding info
        // which in turn is used so setup the environment.
        this.viewer.addOrUpdateEnvironment(this.ifc.regionBoundingInfo);

        const loadPropertySetsPromise = loadPropertySets ? this.ifc.loadPropertySets() : Promise.resolve();

        if (loadGeometry) {
            const geometryBuilder = await this.ifc.geometryBuilder();
            await geometryBuilder.build(({ geometry }) => this.viewer.addOrReplaceMesh(geometry));
        }

        await loadPropertySetsPromise;
    }

    /**
     * Clears api (any loaded IFC files etc are unloaded).
     */
    public clear(): void {
        this.ifc.clear();
        this.viewer.clear();
    }

    /**
     * Iterate the {@link BimIfcObject} instances and return those that match the specified predicate.
     * @param predicate Predicate. Items matching this will be returned in array format.
     * @returns Array with {@link BimIfcObject} instances matched by the specified _predicate_.
     */
    public filter(predicate: BimIfcObjectForEachPredicate): BimIfcObject[] {
        const ret: BimIfcObject[] = [];
        this._bimIfc.foreach((o, recursionOptions) => {
            if (predicate(o, recursionOptions)) {
                ret.push(o);
            }
        });
        return ret;
    }

    /**
     * Intersects all visible BIM objects using ray from origin towards direction
     * @param origin: Vector3
     * @param direction: Vector3
     * @param objects: BimIfcObject[]
     * @returns Intersection[] | undefined
     * @example
     * ```typescript
     * // GetIntersections makes a hit test on all visible BIM objects, from origin in direction and return first object intersection.
     * const intersections = this.api.getIntersections([0, 0, 0], [1, 1, 1]);
     * ```
     * @example
     * ```typescript
     * // GetIntersections makes a hit test on a subset of  BIM objects, from origin in direction and return first object intersection.
     * const intersections = this.api.withSelection(myVisibleObjects, objects => this.api.getIntersections([0, 0, 0], [1, 1, 1]);
     * ```
     * @example
     * ```typescript
     * // GetIntersections makes a hit test on a subset of visible BIM objects, from origin in direction and return first object intersection.
     * const intersections = this.api.getIntersections([0, 0, 0], [1, 1, 1], myObjects);
     * ```
     */
    public getIntersections(
        origin: Vector3,
        direction: Vector3,
        objects: BimIfcObject[] = []
    ): Intersection[] | undefined {
        const viewer = this.viewer;
        if (objects?.length > 0) {
            return this.withVisibleBimObjects(objects, pick);
        } else {
            return pick();
        }

        function pick(): Intersection[] {
            return viewer.camera.pick({ type: PickOptionType.Ray, ray: new Ray(origin, direction) }).hitInfo;
        }
    }

    /**
     * Makes it possible to perform API operations, that operate on the set of visible BIM objects, on a smaller set
     * of (still visible) BIM objects
     * @param visibleBimObjects - Visible objects to run operation on
     * @param action - Operation
     * @example
     * ```typescript
     * // GetIntersections makes a hit test on a subset of visible BIM objects, from origin in direction and return first object intersection.
     * const intersections = this.api.withVisibleBimObjects(myVisibleBimObjects, objects => api.getIntersections([0, 0, 0], [1, 1, 1]));
     * ```
     */
    public withVisibleBimObjects<T>(visibleBimObjects: BimIfcObject[], action: (objects: BimIfcObject[]) => T): T {
        const visibleObjectSet = new Set<BimIfcObject>();
        if (visibleBimObjects?.length > 0) {
            const objectsToTest = new Set([...visibleBimObjects]);

            // Show only objects that match this query
            this.foreach((o) => {
                if (o.isOnGpu()) {
                    if (o.visible()) {
                        visibleObjectSet.add(o);
                    }
                    o.visible(objectsToTest.has(o));
                }
            });
        }
        try {
            return action(visibleBimObjects);
        } finally {
            if (visibleBimObjects) {
                // Show objects that was visible before doing intersection test
                this.ifc.foreach((o) => {
                    o.visible(visibleObjectSet.has(o));
                });
            }
        }
    }

    /**
     * Loads all BIM property sets for all loaded files.
     * @deprecated Use {@link ifc.loadPropertySets} instead.
     */
    public loadPropertySets(): Promise<void> {
        return this._bimIfc.loadPropertySets();
    }

    /**
     * This function zooms the camera to contain BIM objects in view frustum.
     * @param objects Array of BIM objects.
     */
    public zoomToExtent(products: BimIfcObject[]): void {
        if (products.length === 0) {
            return;
        }
        const min = setMax(new Vector3());
        const max = setMin(new Vector3());
        for (const o of products) {
            // Notice that we do not clear transforms cache for performance if we have many items in products we may get a boost
            // if they use the same transforms.
            // Also note that we DO NOT recurse. It is assumed that the users want to zoom in on the given collection ONLY.
            o.aabb(min, max, (o, recursionOptions) => (recursionOptions.stopRecursion = true), false);
        }

        products[0].ifcLoaderElement.transformsRepository.clear();

        this.camera.zoomToExtent([min.x, min.y, min.z, max.x, max.y, max.z]);
    }
    /**
     * Use this function to iterate over all BIM objects.
     * @param action This is a lambda function to evaluate on each BimIfcObject.
     * @example
     * ```typescript
     * api.foreach((o, recursionOptions) => {
     *     if (o.hasGeometry && o.class.id !== "IfcFlowSegment") {
     *         console.log(o.gid);
     *         o.visible(true);
     *     } else {
     *         o.visible(false);
     *     }
     * });
     *
     * ```
     */
    public foreach(a: BimIfcObjectForEachAction): void {
        this._bimIfc.foreach(a);
    }

    /**
     * Create a iterator that can be used to iterate over all {@link BimIfcObject}'s.
     * Same as foreach but with a iterator instead.
     * @returns Iterator that can be used to iterate over all {@link BimIfcObject}'s.
     * @example
     * ```typescript
     * for(const o of api.products()) {
     *     if (o.hasGeometry && o.class.id !== "IfcFlowSegment") {
     *         console.log(o.gid);
     *         o.visible(true);
     *     } else {
     *         o.visible(false);
     *     }
     * };
     *
     * ```
     */
    public products(): IterableIterator<BimIfcObject> {
        return this._bimIfc.products();
    }

    /**
     * @async
     * Updates the viewer with the latest visual changes on BIM objects. See {@link foreach} for an example.
     */
    public async applyVisualChanges(): Promise<void> {
        const geometryBuilder = await this.ifc.geometryBuilder();
        await geometryBuilder.build(({ geometry }) => this.viewer.addOrReplaceMesh(geometry));
    }

    /**
     * Sets a handler to event of given type
     * @deprecated Use {@link onPointerObservable} instead.
     * @param type Type of event. For now only "click" is supported
     * @param handler Function that gets called when the event occurs
     */
    public on(type: 'click', clickEventHandler: ClickEventHandler): void {
        const isGeometryIntersectionEnabled = clickEventHandler.length >= 2;
        this.onPointerObservable.add((pointerInfo) => {
            const mainButton = pointerInfo.twinfinity.button(PredefinedPointerButtonId.Main);
            if (mainButton.event === 'up' && mainButton.duration < pointerDragThresholdTimeInMs) {
                const pickResult = pointerInfo.twinfinity.pick(isGeometryIntersectionEnabled);
                const backwardsCompatibleHitInfo = isGeometryIntersectionEnabled ? pickResult.hitInfo : undefined;
                if (pickResult.type === PickResultType.Empty) {
                    clickEventHandler();
                } else if (pickResult.type === PickResultType.IfcProductMesh) {
                    clickEventHandler(
                        { product: pickResult.ifcProductMesh.ifcProduct, mesh: pickResult.ifcProductMesh },
                        backwardsCompatibleHitInfo,
                        pickResult.mesh
                    );
                } else if (pickResult.type === PickResultType.Mesh) {
                    clickEventHandler(undefined, backwardsCompatibleHitInfo, pickResult.mesh);
                }
            }
        });
    }

    /** Gets a {@link CoordinateTracker} instance. */
    public createCoordinateTracker<Tracked3D extends Vertex3 = any>(): CoordinateTracker<Tracked3D> {
        return new CoordinateTracker<Tracked3D>(this);
    }

    /**
     * Camera is an instance of BimCamera and used for manipulating the 3D camera view of the BIM viewer.
     */
    public readonly camera: BimCamera;

    /**
     * Sets background color in viewer
     * @param color RGB in range 0-1
     * @example
     * ```typescript
     * api.setBackgroundColor([0.95, 0.95, 0.95]);
     * ```
     */
    public setBackgroundColor(color: Color | Color3): void {
        this.viewer.isSkyboxEnabled = false;
        if (!(color instanceof Color3)) {
            color = Color3.FromArray(color);
        }
        const { clearColor } = this.viewer.scene;
        clearColor.r = color.r;
        clearColor.g = color.g;
        clearColor.b = color.b;
    }

    /**
     * Sets highlight color in viewer
     * @param color RGB in range 0-1
     * @param highlightIndex The highlight index tgo change, it's restricted to One, Two and Three
     * because it makes no sense to change the color of the Empty index (since it's only there to denote that a productMesh has no highlight).
     * The highlight index defaults to One
     * @example
     * ```typescript
     * api.setHighlightColor([1.0, 0.0, 0.0]); <-- Sets the color of the first highlight index to solid red
     * api.setHighlightColor([0.0, 1.0, 0.0], HighlightIndex.Two); <-- Sets the color of the second highlight index to solid green
     * ```
     */
    public setHighlightColor(color: Color3, highlightIndex: ColoredHighlightIndexes): void {
        this.viewer.visualSettings.ifc.setHighlightColor(color, highlightIndex);
    }
    /**
     * Gets or sets a value indicating whether to show or hide skybox.
     */
    public get isSkyboxEnabled(): boolean {
        return this.viewer.isSkyboxEnabled;
    }

    public set isSkyboxEnabled(enabled: boolean) {
        this.viewer.isSkyboxEnabled = enabled;
    }
    /**
     * Gets or sets a value indicating whether to hide or show coordinate system axes.
     */
    public get axes(): boolean {
        return this.viewer.axes;
    }
    public set axes(isEnabled: boolean) {
        this.viewer.axes = isEnabled;
    }

    /**
     * Gets or sets options for 3D help grid visualization.
     */
    public get grid(): GridOptions {
        return this.viewer.grid;
    }

    /** Called from {@link createApi}. Subclasses can implement if they need to initialize more data during API creation in {@link createApi} */
    protected async initialize(): Promise<void> {
        return Promise.resolve();
    }
}
