import { BimApi, BimApiLoadOptionsFactory, PostProcessEffects } from '@twinfinity/core';

const main = async () => {
    const api = await BimApi.create('viewer', 'https://bim.bit.projektstruktur.se');
    const containers = await api.backend.getContainers();
    const htw = containers.find((c) => {
        return c.name.toLowerCase() === 'hellotwinfinityworld';
    });
    console.log(htw);

    api.camera.attachBehavior(PostProcessEffects.createLineShading());
    api.camera.attachBehavior(PostProcessEffects.createSSAOPipeline());
    api.camera.attachBehavior(PostProcessEffects.createOutlinePipeline(false));
    await api.setContainer(htw!, BimApiLoadOptionsFactory.all());
};

main();
