/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimProductMeshDescriptor } from './bim-format-types';
import { BimProductMesh } from './BimProductMesh';
import { setMax, setMin } from '../math';
import { FastTransform } from '../math/FastTransform';
import { IBimIfcLoaderElement } from './bim-ifc-loader-element';
import { BimIfcObject } from './bim-ifc-object';
import { Vector3, Geometry, Nullable, FloatArray, Mesh, Engine, VertexBuffer } from './babylonjs-import';
import { GeometryArrayOffset, GeometryArrays, GeometryBuffer } from './GeometryBuffer';
import { telemetry } from '../Telemetry';
import { decodePackedNormal } from './PackedNormal';
import { Writeable } from '../Types';

/** Lookup for finding all the meshes of an IFC object  */
export type BimIfcProductsAndMeshes = ReadonlyMap<BimIfcObject, ReadonlySet<BimProductMesh>>;

/**
 * Represents a handle for a 3D geometry.
 * Can be used to unload the geometry from the GPU.
 */
export class Geometry3dHandle {
    /**
     * Creates a new instance of the {@link Geometry3dHandle} class.
     * @param id - The unique identifier for the geometry.
     * @param aabb - The axis-aligned bounding box of the geometry.
     * @param isTransparent - Indicates whether the geometry is transparent.
     * @param productsAndMeshes - The products and meshes associated with the geometry.
     * @param cullingDistance - The distance at which the geometry should be culled.
     * @param center - The center of the geometry's axis-aligned bounding box in IFC space.
     */
    public constructor(
        readonly id: string,
        readonly aabb: { min: Vector3; max: Vector3 },
        readonly isTransparent: boolean,
        readonly productsAndMeshes: BimIfcProductsAndMeshes,
        readonly cullingDistance: number,
        readonly center: Vector3
    ) {}

    /**
     * Flags all the {@link IfcProductMesh} instances this instance
     * refers to as being not on the GPU. Allows for them to be remeshed
     * in a call tocalling {@link BimIfcLoaderElement.geometryBuilder}.
     * Not intended to be called by the user.
     * @hidden
     */
    public _resetBuildId(): void {
        for (const [, meshes] of this.productsAndMeshes) {
            for (const mesh of meshes) {
                mesh._clearMergeId();
            }
        }
    }
}

/** Represents a geometry that consists of one or more IFC objects */
export interface Geometry3d extends GeometryArrays, Geometry3dHandle {
    /** Applies geometry to a babylon {@link Geometry}. */
    applyToGeometry(babylonGeometry: Geometry, engine: Engine): void;

    /** Applies geometry to a babylon {@link Mesh}. */
    applyToMesh(mesh: Mesh, engine: Engine): void;

    /** Copies normals to existing array. */
    copyNormalsTo<T extends Writeable<ArrayLike<number>>>(dst: T): T;

    toHandle(): Geometry3dHandle;
    cullOnCameraMove: boolean;
}

/**
 * Options for merging vertex data.
 */
export interface VertexDataMergeOptions {
    /**
     * Specifies whether to disable transparency and merge ID checks.
     */
    disableTransparencyAndMergeIdChecks?: boolean;

    /**
     * Specifies whether to include normals in the merged vertex data.
     * Not including it speeds up the {@link VertexDataMerge.merge} operation.
     * However calling {@link VertexDataMerge.applyToMesh} or {@link VertexDataMerge.applyToGeometry}
     * will then not include the normals
     */
    includeNormals?: boolean;

    cullOnCameraMove?: boolean;
}

export class VertexDataMerge implements Geometry3d {
    private static readonly _emptyFloat32Array = new Float32Array(0);
    private static readonly _emptyUint16Array = new Uint16Array(0);
    private _cullingDistance = Number.MAX_VALUE;

    public primitiveCount = 0; // Number of vertieces and normals (1 unit is one triplets x, y, z)
    public indiceCount = 0; // Number of indices. Number of triangles is / 3.

    public readonly itemsToMerge: {
        loaderElement: IBimIfcLoaderElement;
        ifcObject: BimIfcObject;
        mesh: BimProductMesh;
        meshDescriptor: BimProductMeshDescriptor;
        transform: FastTransform;
    }[] = [];

    public get positions(): typeof this._geometry.positions {
        return this._geometry.positions;
    }

    public get uvs(): typeof this._geometry.uvs {
        return this._geometry.uvs;
    }

    public get indices(): typeof this._geometry.indices {
        return this._geometry.indices;
    }

    public get cullingDistance(): number {
        return this._cullingDistance;
    }

    private _geometry: GeometryArrays = {
        positions: VertexDataMerge._emptyFloat32Array,
        uvs: VertexDataMerge._emptyFloat32Array,
        indices: VertexDataMerge._emptyUint16Array
    };
    private _currentOffset: Writeable<GeometryArrayOffset> = {
        indices: 0,
        positions: 0
    };
    private _isTransparent?: boolean;
    private _options: Required<VertexDataMergeOptions>;
    private readonly _aabb = {
        min: Vector3.Zero(),
        max: Vector3.Zero()
    };

    public constructor(
        public readonly id: string,
        private _geometryBuffer: GeometryBuffer,
        options?: VertexDataMergeOptions
    ) {
        this._options = {
            includeNormals: true,
            disableTransparencyAndMergeIdChecks: false,
            cullOnCameraMove: false,
            ...options
        };
    }

    /**
     * Not intended to be called by the user. Use `this.toHandle()._resetBuildId()` instead.
     * @hidden
     */
    public _resetBuildId(): void {}

    public readonly productsAndMeshes = new Map<BimIfcObject, Set<BimProductMesh>>();

    public get cullOnCameraMove(): boolean {
        return this._options.cullOnCameraMove;
    }

    public get isTransparent(): boolean {
        return this._isTransparent ?? false;
    }

    public get aabb(): { min: Vector3; max: Vector3 } {
        return this._aabb;
    }

    public get center(): Vector3 {
        return this.itemsToMerge[0].mesh.centerOfParentIfcCube!;
    }

    /**
     * Applies the geometry data this instances represents ({@link positions}, {@link normals}, {@link uvs}, {@link indices} and {@link aabb})
     * to the specified {@link Mesh}.
     * @param mesh {@link Mesh} to receive geometry data.
     */
    public applyToMesh(mesh: Mesh, engine: Engine): void {
        if (this.itemsToMerge.length === 0) {
            return;
        }

        const g = Geometry.CreateGeometryForMesh(mesh);
        this.applyToGeometry(g, engine);
        mesh.position.copyFrom(this.center);
    }

    /**
     * Applies the geometry data this instances represents ({@link positions}, {@link normals}, {@link uvs}, {@link indices} and {@link aabb})
     * to the specified {@link Geometry}.
     * Notice that the geometry is in IFC space. If geometry is assing to a {@link Mesh} one can use {@link CoordinateSystems.toBabylonTransform}
     * as the mesh pretransform matrix. This will ensure that mesh behaves as if it is in BabylonJS worldspace.
     * @param babylonGeometry {@link Geometry} to receive geometry data.
     */
    public applyToGeometry(babylonGeometry: Geometry, engine: Engine): void {
        if (this.itemsToMerge.length === 0) {
            return;
        }

        const babylonGeometryAny = babylonGeometry as any;
        const oldUpdateExtend = babylonGeometryAny._updateExtend;

        // Optimization. Normally babylonjs will calculate extends (when .setAllVerticesData is called)
        // by iterating all positions however since we already know max and min we can simply
        // skip that and set it directly
        babylonGeometryAny._updateExtend = (data: Nullable<FloatArray> = null): void => {
            babylonGeometryAny._extend = {
                minimum: this.aabb.min.clone(),
                maximum: this.aabb.max.clone()
            };
        };

        babylonGeometry.setVerticesData(VertexBuffer.PositionKind, this.positions);

        if (this._options.includeNormals) {
            // TODO Could probably be optimized to use a single cached (large enough) float array instead of
            // recreating it
            babylonGeometry.setVerticesData(
                VertexBuffer.NormalKind,
                this.copyNormalsTo(new Float32Array(this.positions.length))
            );
        }

        babylonGeometry.setVerticesData(VertexBuffer.UVKind, this.uvs);
        babylonGeometry.setIndices(this.indices);

        babylonGeometryAny._updateExtend = oldUpdateExtend;
    }

    public copyNormalsTo<T extends Writeable<ArrayLike<number>>>(dst: T): T {
        if (dst.length < this.positions.length) {
            throw new Error('Normals array must be at least this.primitiveCount * 3 elements');
        }

        const uvLen = this.uvs.length;
        for (let packedNormalOffset = 1, normalOffset = 0; packedNormalOffset < uvLen; packedNormalOffset += 2) {
            // The normals received from the backend is encoded into a uint16, which in turn are stored in the first two bytes of the uv.y component.
            const packedNormal = this.uvs[packedNormalOffset];
            const normal = decodePackedNormal[packedNormal];
            dst[normalOffset++] = normal.x;
            dst[normalOffset++] = normal.y;
            dst[normalOffset++] = normal.z;
        }
        return dst;
    }

    public add(
        mesh: BimProductMesh
        // worldSpaceBoundingInfo: BoundingInfo,
    ): void {
        const product = mesh.ifcProduct;
        const transform = mesh.ifcProduct.ifcLoaderElement.transformsRepository.getTransform(mesh);
        const meshDescriptor = product.meshDescriptor(mesh);
        if (meshDescriptor.p === 0 || meshDescriptor.i === 0) {
            telemetry.trackTrace({ message: `Empty mesh: ${product.gid}:${mesh.mi}` });
            return;
        }
        // ensure that all items we attempt to merge have the same style. Otherwise it will not be possible
        // to use 1 drawcall to draw them
        const style = product.style(mesh);
        const isTransparent = style.a < 255;
        this._isTransparent = this._isTransparent === undefined ? isTransparent : this._isTransparent;

        if (!this._options.disableTransparencyAndMergeIdChecks) {
            if (this._isTransparent !== isTransparent) {
                throw new Error('Cannot merge meshes with transparent and opqaue styles');
            }

            if (this.itemsToMerge.length > 0 && this.itemsToMerge[0].mesh.mergeId !== mesh.mergeId) {
                throw new Error('Cannot merge meshes with different mergeIds');
            }
        }

        let meshSet = this.productsAndMeshes.get(product);

        if (meshSet === undefined) {
            meshSet = new Set<BimProductMesh>();
            this.productsAndMeshes.set(product, meshSet);
        }
        meshSet.add(mesh);

        this.itemsToMerge.push({
            loaderElement: product.ifcLoaderElement,
            ifcObject: product,
            mesh,
            meshDescriptor,
            transform
        });

        this._cullingDistance = Math.min(mesh.cullingDistance, this.cullingDistance);
        this.primitiveCount += meshDescriptor.p;
        this.indiceCount += meshDescriptor.i;
        this._geometryBuffer.maximizeLength(this.primitiveCount, this.indiceCount);
    }

    public merge(): Geometry3d {
        const itemsToMergeLen = this.itemsToMerge.length;
        if (itemsToMergeLen === 0) {
            telemetry.trackTrace({ message: 'nothing to merge' });
            return this;
        }

        this.allocateGeometryArrays();
        setMax(this._aabb.min);
        setMin(this._aabb.max);

        const center = this.center;
        const emptyTransform = FastTransform.identity();
        for (let mergeItemIdx = 0; mergeItemIdx < itemsToMergeLen; ++mergeItemIdx) {
            const mergeItem = this.itemsToMerge[mergeItemIdx];

            const transform = mergeItem.transform.translateToRef(center, emptyTransform);
            mergeItem.loaderElement.writeVertexData(
                mergeItem.mesh,
                transform,
                this._geometry,
                this._currentOffset,
                this.aabb,
                this._options
            );
            this._currentOffset.indices += mergeItem.meshDescriptor.i;
            this._currentOffset.positions += mergeItem.meshDescriptor.p;
        }

        // TODO Should we attempt to remove duplicate vertices???? After all we have now merged multiple geometries into one
        // perhaps there are a number of vertices that are now duplicates and could be removed
        return this;
    }

    public toHandle(): Geometry3dHandle {
        return new Geometry3dHandle(
            this.id,
            {
                min: this.aabb.min.clone(),
                max: this.aabb.min.clone()
            },
            this.isTransparent,
            new Map(this.productsAndMeshes),
            this.cullingDistance,
            this.center.clone()
        );
    }

    private allocateGeometryArrays(): void {
        if (this.positions !== VertexDataMerge._emptyFloat32Array) {
            return; // have already allocated
        }

        this._geometry = this._geometryBuffer.malloc(this.primitiveCount, this.indiceCount);
    }
}
