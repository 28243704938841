/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { Extensions } from '../Extensions';
import { Camera, Mesh, Ray, TargetCamera, Vector3 } from './babylonjs-import';
import { Materials } from './materials';
import { Geometry3d, BimIfcProductsAndMeshes } from './vertex-data-merge';

/**
 * Additional data that decorates {@link Mesh} instances with geometry
 * that represents IFC objects.
 */
export class TwinfinityIfcMeshExtension {
    private static readonly _orthoFrustumSize = { x: 0, y: 0 };
    private static readonly _tmp = { vectorA: Vector3.Zero(), ray: new Ray(Vector3.Zero(), Vector3.Up(), 1000) };
    private _checkedOnState = {};
    private _hasVisibleIfcObjects = false;

    /** Number of indices this {@link Mesh} contains. */
    public readonly indiceCount: number;

    /** Number of vertices, uv's and normals this {@link Mesh} contains. */
    public readonly primitiveCount: number;

    /** `true` if this {@link Mesh} contains transparent objects
     * (in which case it cannot contain opaque objects and `false` if it does) */
    public readonly isTransparent: boolean;

    /** All {@link BimIfcObject}'s and their {@link BimProductMesh}'es that
     * makes up the {@link Mesh} geometry.' */
    public readonly productsAndMeshes: BimIfcProductsAndMeshes;

    /** Distance from camera where `_mesh` will become visibile. If < 0 it is always visible. */
    public readonly cullingDistance: number;

    public cullOnCameraMove: boolean;
    public hasOutlinedIfcObjects: boolean;

    /**
     * Constructor
     * @param _mesh {@link Mesh} instance to connect the extension to.
     * @param _materials {@link Materials} instance
     * @param geometry3D {@link Geometry3dHandle} instance representing the geometry which will be applied to `_mesh`.
     */
    public constructor(private readonly _mesh: Mesh, private readonly _materials: Materials, geometry3D: Geometry3d) {
        this.indiceCount = geometry3D.indices.length;
        this.primitiveCount = geometry3D.positions.length;
        this.isTransparent = geometry3D.isTransparent;

        this.productsAndMeshes = geometry3D.productsAndMeshes;
        this.cullingDistance = geometry3D.cullingDistance;
        this.cullOnCameraMove = geometry3D.cullOnCameraMove;
    }

    private _pivotTargetSphereIntersectionTest = (camera: TargetCamera): boolean => {
        const viewer = camera.twinfinity.viewer;
        if (!viewer) {
            throw new Error('Viewer is undefined.');
        }

        const bInfo = this._mesh.getBoundingInfo();
        const pivotPointSphereRadius = viewer.performance.ifc.hideExpensiveMeshesOnCameraMove.pickBoundingSphereRadius;

        const boundingSphereCenterDistanceToCameraTarget = bInfo.boundingSphere.centerWorld
            .subtract(camera.twinfinity.pivot.target)
            .length();
        return boundingSphereCenterDistanceToCameraTarget < pivotPointSphereRadius + bInfo.boundingSphere.radiusWorld;
    };

    /**
     * `true` if visible from the supplied cameras point of view. Otherwise `false`.
     * @param camera Camera to use when checking visibility
     */
    public isVisible(camera: Camera, distance?: number): boolean {
        const viewer = camera.twinfinity.viewer;
        if (!viewer) {
            throw new Error('Viewer is undefined.');
        }

        const hasMaterialsStateChanged = this.hasMaterialsStateChanged();
        if (hasMaterialsStateChanged) {
            this._hasVisibleIfcObjects = this.hasVisibleIfcObjects();
            this.hasOutlinedIfcObjects = this._hasOutlinedIfcObjects();
        }
        let isVisible = this._hasVisibleIfcObjects && !this.isDistanceCulled(camera, distance ?? this.cullingDistance);

        const cullOnCameraMove = viewer.performance.ifc.hideExpensiveMeshesOnCameraMove;
        const lastCheck = viewer._cameraStateSnapshot?.lastCheckTimestamp;
        const lastChange = viewer._cameraStateSnapshot?.lastChangeTimestamp;
        if (
            isVisible &&
            cullOnCameraMove.isEnabled &&
            lastCheck &&
            lastChange &&
            lastCheck - lastChange < cullOnCameraMove.delayUntilCullingStops
        ) {
            camera.getForwardRayToRef(TwinfinityIfcMeshExtension._tmp.ray);
            const poiRayIntersectsMesh =
                cullOnCameraMove.keepMeshesNearPointOfInterest &&
                TwinfinityIfcMeshExtension._tmp.ray.intersectsBoxMinMax(
                    this._mesh.getBoundingInfo().boundingBox.minimumWorld,
                    this._mesh.getBoundingInfo().boundingBox.maximumWorld
                );

            const pivotPointTargetBoundingSphereIntersectsMesh =
                cullOnCameraMove.keepMeshesNearPivotPoint && camera instanceof TargetCamera
                    ? this._pivotTargetSphereIntersectionTest(camera)
                    : false;

            isVisible =
                !this._mesh.twinfinity.ifc!.cullOnCameraMove ||
                pivotPointTargetBoundingSphereIntersectsMesh ||
                poiRayIntersectsMesh;
        }
        return isVisible;
    }

    public hasMaterialsStateChanged(): boolean {
        if (this._checkedOnState !== this._materials.state) {
            this._checkedOnState = this._materials.state;
            return true;
        }
        return false;
    }

    private hasVisibleIfcObjects(): boolean {
        for (const [, meshes] of this.productsAndMeshes.entries()) {
            for (const mesh of meshes) {
                if (mesh.visible || mesh.ghostOutline) {
                    return true;
                }
            }
        }
        return false; // Not needed but is nice when debugging
    }

    private _hasOutlinedIfcObjects(): boolean {
        for (const [, meshes] of this.productsAndMeshes.entries()) {
            for (const mesh of meshes) {
                if (mesh.outline) {
                    return true;
                }
            }
        }
        return false; // Not needed but is nice when debugging
    }

    /**
     * Gets the distance from an mesh to the camera
     * @param camera Camera to use when checking distance
     */
    public distanceToCamera(camera: Camera): number {
        const bSphere = this._mesh.getBoundingInfo().boundingSphere;
        if (camera.mode === Camera.PERSPECTIVE_CAMERA) {
            const tmp = bSphere.centerWorld.subtractToRef(
                camera.globalPosition,
                TwinfinityIfcMeshExtension._tmp.vectorA
            );
            return tmp.length() - bSphere.radius;
        } else {
            // Ortographic camera
            camera.twinfinity.getOrthoFrustumSizeToRef(TwinfinityIfcMeshExtension._orthoFrustumSize);
            return TwinfinityIfcMeshExtension._orthoFrustumSize.x;
        }
    }

    private isDistanceCulled(camera: Camera, cullingDistance: number): boolean {
        // GOOD IDEA? If camera is stationary then we could adjust lod distance dynamically so we show objects
        // further out? Could make things jerky when camera starts to move again.

        if (cullingDistance < 0) {
            return false;
        }

        const meshDistanceToCamera = this.distanceToCamera(camera);

        if (camera.mode === Camera.PERSPECTIVE_CAMERA) {
            // DO not show mesh if it is further away from the camera than its culling distance
            const isCulled = meshDistanceToCamera > cullingDistance;
            return isCulled;
        }

        // Ortographic camera
        // 2 is just a magic constant to give illusion of aprox the same culling behavior as in the perspective camera.
        const orthoCullingDistance = cullingDistance * 2 * camera.getEngine().getHardwareScalingLevel();
        const isCulled = meshDistanceToCamera > orthoCullingDistance;
        return isCulled;
    }
}
/**
 * Twinfinity extensions for {@link Mesh}.
 */
export interface TwinfinityMeshExtensions {
    /** If set then {@link TwinfinityMeshExtensions.parent} contains IFC data. */
    ifc?: TwinfinityIfcMeshExtension;
    /** Parent {@link Mesh}. */
    readonly parent: Mesh;

    /**
     * Clear cached vertex data of {@link TwinfinityMeshExtensions.parent}. Basically this removes the
     * cached vertex data from RAM (but not from GPU). This has the same
     * effects as calling {@link Scene.clearCachedVertexData} (except that it only
     * affects this particular {@link Mesh}).
     */
    clearCachedVertexData(): void;

    /*private createNormalsMesh(mesh: Mesh, size: number, color: Color3, sc: Scene): Mesh {
        const normals = mesh.getVerticesData(VertexBuffer.NormalKind)!;
        const positions = mesh.getVerticesData(VertexBuffer.PositionKind)!;

        //   const colors: Color4[][] = [];
        //   const color4 = color.toColor4();
        const lines = [];
        for (let i = 0; i < normals!.length; i += 3) {
            const v1 = Vector3.FromArray(positions!, i);
            const v2 = v1.add(Vector3.FromArray(normals!, i).scaleInPlace(size));
            lines.push([v1, v2]);
        }

        //   for (let i = 0; i < normals.length; i += 3) {
        //       const v1 = Vector3.FromArray(positions, i);
        //       const v2 = v1.add(Vector3.FromArray(normals, i).scaleInPlace(size));
        //       lines.push([v1.add(mesh.position), v2.add(mesh.position)]);
        //       colors.push([color4, color4]);
        //   }

        const normalLines = MeshBuilder.CreateLineSystem(
            `${mesh.id}.normalLines`,
            {
                lines,
            },
            sc,
        );
        normalLines.parent = mesh;
        normalLines.color = color;
        //   normalLines.mater
        normalLines.isPickable = false;
        return normalLines;
    }*/
}

declare module '@babylonjs/core/Meshes/mesh' {
    /**
     * Twinfinity extension property for {@link Mesh}.
     */
    export interface Mesh {
        /** Twinfinity extensions. */
        readonly twinfinity: TwinfinityMeshExtensions;
    }
}

function clearCachedVertexData(mesh: Mesh): void {
    if (!mesh.geometry) {
        return;
    }
    if (mesh.geometry._indices) {
        mesh.geometry._indices = [];
    }
    if (mesh.geometry._vertexBuffers) {
        for (const vb of Object.values(mesh.geometry._vertexBuffers)) {
            // if (name === 'uv') {
            //     continue;
            // }
            vb._buffer._data = null;
        }
    }
}

Object.defineProperty(Mesh.prototype, Extensions.TWINFINITY_PROPERTY, {
    get: function (this: Mesh): TwinfinityMeshExtensions {
        const ret = {
            ifc: undefined,
            parent: this,
            clearCachedVertexData: () => clearCachedVertexData(this)
        };
        Object.defineProperty(this, Extensions.TWINFINITY_PROPERTY, {
            value: ret
        });
        return ret;
    },
    set: function (this: Mesh, p: TwinfinityIfcMeshExtension): void {}
});
