import { Camera, Effect, PostProcess } from '../loader/babylonjs-import';
import { TwinfinityPostProcess } from './TwinfinityPostProcess';

const SHADER_NAME_PREFIX = 'outline_applier';
const SCREEN_SAMPLER = 'sceneSampler';
const OUTLINE_SAMPLER = 'outlineSampler';

/**
 * OutlineApplier applies the outline on top of the screen blit
 */
export class OutlineApplier extends TwinfinityPostProcess {
    private static readonly _fragmentShaderSource = `#ifdef GL_ES
        precision highp float;
    #endif
    varying vec2 vUV;

    // Samplers
    uniform sampler2D ${SCREEN_SAMPLER};
    uniform sampler2D ${OUTLINE_SAMPLER};
    
    void main(void)
    {
        vec2 sampleUV = vUV;

        vec4 screenFragment = texture2D(${SCREEN_SAMPLER}, sampleUV);
        vec4 outline = texture2D(${OUTLINE_SAMPLER}, sampleUV);

        // The ambient occlusion holds the value of the actual occlusion, not reverse
        gl_FragColor = screenFragment + outline;
    }`;

    private _blitedScreen: PostProcess;
    private _renderScale: number;

    constructor(blitedScreen: PostProcess, renderScale: number) {
        super();
        this._blitedScreen = blitedScreen;
        this._renderScale = renderScale;
    }

    protected initialize(camera: Camera): PostProcess {
        const engine = camera.getEngine();
        const defines = new Array<string>();

        if (this._postProcess === undefined) {
            Effect.ShadersStore[SHADER_NAME_PREFIX + 'FragmentShader'] = OutlineApplier._fragmentShaderSource;

            const uniforms: string[] = [];

            const newPostProcess = new PostProcess(
                'Outline_Applier',
                SHADER_NAME_PREFIX,
                uniforms,
                [SCREEN_SAMPLER, OUTLINE_SAMPLER],
                this._renderScale,
                null,
                undefined,
                engine,
                false,
                defines.join('\n')
            );

            const viewer = camera.getScene().twinfinity.viewer;
            if (viewer) {
                newPostProcess.onApplyObservable.add((effect) => {
                    effect.setTextureFromPostProcess(SCREEN_SAMPLER, this._blitedScreen);
                    effect.setTextureFromPostProcess(OUTLINE_SAMPLER, newPostProcess); // Babylon is trippy in the way that this call will set the texture to the current screen as it was BEFORE newPostProcess is applied
                });
            } else {
                throw new Error('No viewer created?');
            }

            return newPostProcess;
        } else return this._postProcess;
    }
}
