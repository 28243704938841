import { Vector3 } from '../loader/babylonjs-import';
import { BaseLineShading } from './BaseLineShading';
import { DitherBlurPipeline } from './DitherBlurPipeline';
import { LineShading } from './LineShading';
import { NormalsFromDepthLineShading } from './NormalsFromDepthLineShading';
import { OutlinePipeline } from './OutlinePipeline';
import { SSAOPipeline } from './SSAOPipeline';

/**
 * Contains static methods that return new instances of post-process effects.
 */
export class PostProcessEffects {
    /**
     * Creates a new instance of the Dither Blur post-process effect. This is used to blur pixels masked as being dithered (which is only opaque objects with alpha < 1.0)
     * @param kernel The size of the kernel to be used when computing the blur. Defaults to 16 if no value is provided. It does not correspond exactly to the number of fragments sampled but still raising this number will decrease performance
     * @returns The newly created instance.
     */
    public static createDitherBlur(kernel = 16.0): DitherBlurPipeline {
        return new DitherBlurPipeline(kernel);
    }

    /**
     * Creates a new instance of the LineShading post-process effect, or if normalsSensitive === `true`, NormalsFromDepthLineShading.
     * @param lineThickness Thickness of the lines, a value between 0 and 1.
     * @returns The newly created instance.
     */
    public static createLineShading(lineThickness = 0.3, normalsSensitive = true): BaseLineShading {
        if (normalsSensitive) {
            return new NormalsFromDepthLineShading(lineThickness, 0.35);
        } else {
            return new LineShading(lineThickness);
        }
    }

    /**
     * Creates a new instance of the SSAO pipeline post-process effect
     * @param samplesCount Number of SSAO samples per frame, higher gives better SSAO quality but reduces performance and the other way around, default is 46, has to be whole numbers
     * @param strength Strength of SSAO, higher value means larger impact of SSAO, default is 1.0
     * @param blurSize How many pixels of the SSAO result that is blurred, has to be a whole number, default is 16
     * @param radius How spaced out the SSAO samples are, default is 0.08
     * @param renderScale The resolution at which the SSAO is calculated, reducing this below 1.0 means that the SSAO calculations are made at a lower scale and then scaled back again to fit the current render frame. This means that it will use less GPU resources, but also that it will look blurry. Default is 1.0, recommended values are 1.0, 0.5 and 0.25
     * @returns The newly created instance of the ssao pipeline
     */
    public static createSSAOPipeline(
        samplesCount?: number,
        strength?: number,
        blurSize?: number,
        radius?: number,
        renderScale?: number
    ): SSAOPipeline {
        return new SSAOPipeline(samplesCount, strength, blurSize, radius, renderScale);
    }

    /**
     * Returns a postprocess that outlines all IFC meshes that are marked as outlined
     * @param unifiedOutline Wheter or not to have all outlined meshes have a unified outline
     * @param outlineColor The color of the outline
     */
    public static createOutlinePipeline(
        unifiedOutline: boolean,
        outlineColor: Vector3 = new Vector3(0.0, 1.0, 0.0)
    ): OutlinePipeline {
        return new OutlinePipeline(0.5, unifiedOutline, outlineColor);
    }
}
