import { Camera, Effect, PostProcess, RenderTargetTexture, Vector2, Vector3 } from '../loader/babylonjs-import';
import { RESOLUTION } from '../loader/ShaderSnippets';
import { TwinfinityPostProcess } from './TwinfinityPostProcess';

const SHADER_NAME_PREFIX = 'outlines';
const OUTLINES_SAMPLER = 'OUTLINES_TEXTURE_SAMPLER';

const SAMPLE_KERNEL_SIZE = 'sampleKernelSize';
const SAMPLE_KERNEL = 'sampleKernel';
const OUTLINE_COLOR = 'outlineColor';

const UNIFIED_OUTLINE = 'UNIFIED_OUTLINE';

export class OutlineCalculator extends TwinfinityPostProcess {
    private _samples = 4;
    private _kernel: Vector2[] = [];

    private static readonly _fragmentShaderSource = `
    #ifdef GL_ES
        precision highp float;
    #endif
    varying vec2 vUV;

    // Samplers
    uniform sampler2D ${OUTLINES_SAMPLER};
    uniform vec2 ${RESOLUTION};
    uniform vec3 ${OUTLINE_COLOR};
    uniform vec2 ${SAMPLE_KERNEL}[${SAMPLE_KERNEL_SIZE}];

    void main(void)
    {
        vec2 sampleUV = vUV;
    
        vec3 centerOutlineSample = texture2D(${OUTLINES_SAMPLER}, sampleUV).rgb;
        float edge = 0.0;

        for (int i = 0; i < ${SAMPLE_KERNEL_SIZE}; i++) {
            
            vec2 offset = ${SAMPLE_KERNEL}[i] * ${RESOLUTION};
            vec3 kernelSample = texture2D(${OUTLINES_SAMPLER}, sampleUV + offset).rgb;

            #ifdef ${UNIFIED_OUTLINE}
                float centerIsWritten = step(0.01, length(centerOutlineSample));
                float sampleIsWritten = step(0.01, length(kernelSample));

                edge = max(edge, step(0.01, abs(centerIsWritten - sampleIsWritten)));
            #else
                float rDiff = abs(centerOutlineSample.r - kernelSample.r);
                float gDiff = abs(centerOutlineSample.g - kernelSample.g);
                float bDiff = abs(centerOutlineSample.b - kernelSample.b);

                float maxDiff = max(max(rDiff, gDiff), bDiff);
                edge = max(edge, step(0.01, maxDiff));
            #endif
        }

        gl_FragColor = vec4(${OUTLINE_COLOR} * edge, edge);
    }`;

    private getDefines(): string {
        const defines: string[] = [];

        defines.push(`#define ${SAMPLE_KERNEL_SIZE} ${this._samples}`);

        if (this._unifiedOutlines) {
            defines.push(`#define ${UNIFIED_OUTLINE}`);
        }
        return defines.join('\n');
    }

    private getUniforms(): string[] {
        const uniforms: string[] = [RESOLUTION, OUTLINE_COLOR];

        for (let i = 0; i < this._kernel.length; i++) {
            uniforms.push(SAMPLE_KERNEL + '[' + i + ']');
        }

        return uniforms;
    }

    private getSamplers(): string[] {
        return [OUTLINES_SAMPLER];
    }

    protected initialize(camera: Camera): PostProcess {
        const engine = camera.getEngine();

        this._kernel.push(new Vector2(-1.0, 0.0));
        this._kernel.push(new Vector2(1.0, 0.0));
        this._kernel.push(new Vector2(0.0, -1.0));
        this._kernel.push(new Vector2(0.0, 1.0));

        const renderWidth = engine.getRenderWidth();
        const renderHeight = engine.getRenderHeight();

        if (this._postProcess === undefined) {
            Effect.ShadersStore[SHADER_NAME_PREFIX + 'FragmentShader'] = OutlineCalculator._fragmentShaderSource;
            const newPostProcess = new PostProcess(
                'OUTLINES',
                SHADER_NAME_PREFIX,
                this.getUniforms(),
                this.getSamplers(),
                {
                    // Do not include scale here, because that would mean that the postprocess before this one (this blit) would be resized, because Babylonjs has really weird rules for this
                    width: renderWidth,
                    height: renderHeight
                },
                null,
                undefined,
                engine,
                false,
                this.getDefines()
            );

            newPostProcess.onApplyObservable.add((effect) => {
                effect.setTexture(OUTLINES_SAMPLER, this._outlineRenderTargetTexture);
                const resolutionX = 1.0 / (renderWidth * this._renderScale);
                const resolutionY = 1.0 / (renderHeight * this._renderScale);

                effect.setFloat2(RESOLUTION, resolutionX, resolutionY);
                effect.setVector3(OUTLINE_COLOR, this._outlineColor);

                for (let i = 0; i < this._kernel.length; i++) {
                    const kernelSample = this._kernel[i];
                    const sampleKernelUniformName = `${SAMPLE_KERNEL}[${i}]`;
                    effect.setVector2(sampleKernelUniformName, kernelSample);
                }
            });

            this._postProcess = newPostProcess;

            return newPostProcess;
        } else return this._postProcess;
    }

    constructor(
        private readonly _outlineRenderTargetTexture: RenderTargetTexture,
        private readonly _renderScale: number,
        private readonly _unifiedOutlines: boolean,
        private readonly _outlineColor: Vector3
    ) {
        super();
    }
}
