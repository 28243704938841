import {
    BIT_SHIFTING,
    RESOLUTION,
    createPropertiesTextureReadShaderCode,
    DITHER_DISCARD,
    DITHER_UNIFORMS,
    DITHERING,
    WEBGL2,
    REQUIRES_WORLD_FRAGMENT,
    COLOR_PIXEL_INDEX,
    VISIBLITY_PROPERTY,
    HIGHLIGHT_INDEX_PROPERTY,
    PROPERTIES_CASTED_A,
    USE_HSV_MAPPING,
    HSV_2_RGB_SMOOTH,
    HSV_VALUES,
    RGB_2_HSV,
    HSV_COLOR_CHANGER,
    HIGHLIGHT_COLORS
} from '../ShaderSnippets';
import { packedNormalPackSize } from '../PackedNormal';
import { AbstractMesh, Color4, Scene, Vector3, Color3, Vector2, Matrix } from '../babylonjs-import';
import { UniformsAndDefinesAndSamplers, clipPlanesStrings } from './CullingMaterial';
import { IOS_BIT_OPERATIONS } from '../ShaderSnippets';
import { LazyResizableRawTexture } from '../LazyResizableRawTexture';
import { DitheringMaterial } from './DitheringMaterial';
import { DitherTexture } from './DitherTexture';

export enum IfcMaterialRenderProperty {
    depthWriteIgnoresVisibility = 24 /* 0b0001 */,
    visibility = 25 /* 0b0010 */
}

export enum IfcMaterialHighlightIndex {
    None = 0b00, // 0
    One = 0b01, // 1
    Two = 0b10, // 2
    Three = 0b11 // 3
}

export type ColoredHighlightIndexes = Exclude<IfcMaterialHighlightIndex, IfcMaterialHighlightIndex.None>;

const DIFFUSE_SAMPLER = 'diffuseSampler';
const PROPERTIES_SAMPLER = 'propertiesSampler';

const DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION = 'directionalLightViewSpace';
const DIRECTIONAL_LIGHT_DEPTH_VALUES = 'directionalLightDepthValues';
const DIRECTIONAL_LIGHT_COLOR = 'directionalLightColor';
const DIRECTIONAL_LIGHT_STRENGTH = 'directionalLightStrength';
const DIRECTIONAL_LIGHT_LAMBERT_STRENGTH = 'directionalLightLambertStrength';
const DIRECTIONAL_LIGHT_SPECULAR_STRENGTH = 'directionalLightSpecularStrength';
const HEMISPHERIC_LIGHT_WORLD_SPACE_DIRECTION = 'hemisphericLightWorldSpace';
const HEMISPHERIC_LIGHT_COLOR = 'hemisphericLightColor';
const HEMISPHERIC_LIGHT_STRENGTH = 'hemisphericLightStrength';
const SIZE = 'size';
const PACK_SIZE = 'packSize';
const WORLD_MATRIX = 'worldMatrix';
const WORLD_VIEW_PROJECTION = 'worldViewProjection';
const EYE_POSITION_WORLD_SPACE = 'eyePositionW';
const AMBIENT_COLOR = 'ambientColor';
const GROUND_COLOR = 'groundColor';
const CAMERA_POSITION_WORLD_SPACE = 'cameraPositionW';
const MATERIAL_DIFFUSE_COLOR = 'materialDiffuseColor';

const MATERIAL_DIFFUSE_COLOR_DEFAULT_VALUE: Color4 = new Color4(0.93, 0.93, 0.93, 1.0);

const IFCMaterialVertexShader = /*glsl*/ `#define PI 3.14
#ifdef GL_ES
    precision highp float;
#endif
attribute vec3 position;
attribute vec2 uv;

${BIT_SHIFTING}

uniform mat4 worldViewProjection;
uniform vec3 ${CAMERA_POSITION_WORLD_SPACE};
uniform mat4 worldMatrix;
uniform float packSize;
uniform vec3 ${SIZE};
uniform vec3 eyePositionW; // Remember that the eye position is always (0, 0, 0) when it's in viewspace 

#ifdef ${WEBGL2}
    out float vColorPixelIndex;
    out vec3 vPositionW;
#else
    varying float vColorPixelIndex;
    varying vec3 vPositionW;
#endif

void main(void) {                
    vec4 worldSpaceVertex = worldMatrix * vec4(position, 1.0);
    vPositionW = worldSpaceVertex.xyz;
    vColorPixelIndex = uv.x;
    gl_Position = worldViewProjection * vec4(position, 1.0);
}    

`;

const IFCMaterialFragmentShader = /*glsl*/ `
#ifdef GL_ES
    precision highp float;
#endif
uniform vec3 ${HIGHLIGHT_COLORS}[3];
#define PI 3.14
uniform mat4 ${WORLD_VIEW_PROJECTION};
uniform vec3 ${CAMERA_POSITION_WORLD_SPACE};
uniform vec3 ${GROUND_COLOR};
uniform vec4 ${MATERIAL_DIFFUSE_COLOR};
uniform vec3 ${AMBIENT_COLOR};
uniform vec3 ${HEMISPHERIC_LIGHT_COLOR};
uniform float ${HEMISPHERIC_LIGHT_STRENGTH};
uniform vec3 ${HEMISPHERIC_LIGHT_WORLD_SPACE_DIRECTION};
uniform vec3 ${DIRECTIONAL_LIGHT_COLOR};
uniform vec3 ${DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION};
uniform float ${DIRECTIONAL_LIGHT_STRENGTH};
uniform sampler2D ${DIFFUSE_SAMPLER};
uniform sampler2D ${PROPERTIES_SAMPLER};
uniform float packSize;
uniform mat4 worldMatrix;
uniform vec3 ${SIZE};

uniform float ${DIRECTIONAL_LIGHT_LAMBERT_STRENGTH};
uniform float ${DIRECTIONAL_LIGHT_SPECULAR_STRENGTH};

${clipPlanesStrings.CLIP_PLANE_UNIFORMS}

${BIT_SHIFTING}

#ifdef ${WEBGL2}
    in float vColorPixelIndex;
    in vec3 vPositionW;
#else
    varying float vColorPixelIndex;
    varying vec3 vPositionW;
#endif


${DITHER_UNIFORMS}

vec3 computeHemisphericLightingDiffuseTerm(vec3 normalW, vec3 hemisphericLightDirectionW, vec3 diffuseColor, vec3 groundColor) {
	float ndl = dot(normalW, hemisphericLightDirectionW) * 0.5 + 0.5;
	vec3 diffuse = mix(groundColor, diffuseColor, ndl);
	
	return diffuse;
}

vec3 computeDirectionalLight(vec3 worldE, vec3 worldN) {
    
    float shininess = 128.0; // Hardcoded to 128, since we don't have shininess in the properties texture.
    
    vec3 normalReflectedLightDirection = reflect(normalize(${DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION}), worldN);

    float specular = pow(max(0.0, dot(normalReflectedLightDirection, worldE)), shininess) * ${DIRECTIONAL_LIGHT_SPECULAR_STRENGTH};

    float diffuse = max(dot(worldN, -normalize(${DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION})), 0.0) * ${DIRECTIONAL_LIGHT_LAMBERT_STRENGTH};

    return max(${DIRECTIONAL_LIGHT_COLOR} * (diffuse + specular), vec3(0.0));
}

#ifdef ${USE_HSV_MAPPING}
    ${HSV_2_RGB_SMOOTH}
    ${RGB_2_HSV}
    uniform vec3 ${HSV_VALUES};
#endif

void main(void) {    

    ivec2 textureSize = textureSize(${DIFFUSE_SAMPLER}, 0);    
    
    ${COLOR_PIXEL_INDEX}
    vec4 diffuseColor = texelFetch(${DIFFUSE_SAMPLER}, ivec2(pixelIndex), 0);
    
    if (diffuseColor.a == 0.0) {
        // Good for debugging if a texture fails for some reason
        // if (int(colorPixelIndex) % textureSize.x == 0 && diffuseColor == vec4(0, 0, 0, 0)) {
        //     glFragColor = vec4(1, 0, 0, 1);
        //     return;
        // }
        discard;
    }

    #ifdef ${REQUIRES_WORLD_FRAGMENT}
        vec3 worldSpaceVertex = vPositionW + ${CAMERA_POSITION_WORLD_SPACE};
    #endif
    ${clipPlanesStrings.CLIP_PLANE_DISCARDING}
    #ifdef ${DITHERING}
        float opaqueness = min(diffuseColor.a, 1.0);
        float distanceImpact = 0.25;
        ${DITHER_DISCARD}
    #endif

    
    vec3 worldE = normalize(${CAMERA_POSITION_WORLD_SPACE} - vPositionW);

    // Replace vec3 fdx = dFdx(vPos)  by:
    vec3 fdx = vec3(dFdx(vPositionW.x),dFdx(vPositionW.y),dFdx(vPositionW.z));
    // Replace vec3 fdy = dFdy(vPos)  by:
    vec3 fdy = vec3(dFdy(vPositionW.x),dFdy(vPositionW.y),dFdy(vPositionW.z));

    
    vec3 normal = normalize(cross(fdy, fdx));
    vec3 hemisphericLighting = computeHemisphericLightingDiffuseTerm(normal, ${HEMISPHERIC_LIGHT_WORLD_SPACE_DIRECTION}, ${HEMISPHERIC_LIGHT_COLOR}, ${GROUND_COLOR});
    vec3 directionalLighting = computeDirectionalLight(worldE, normal);
    
    vec3 finalDiffuse = clamp((directionalLighting * ${DIRECTIONAL_LIGHT_STRENGTH} + hemisphericLighting * ${HEMISPHERIC_LIGHT_STRENGTH}) * ${MATERIAL_DIFFUSE_COLOR}.rgb + ${AMBIENT_COLOR}, 0.0, 1.0) * diffuseColor.rgb;
    
    ${HSV_COLOR_CHANGER}

    ${createPropertiesTextureReadShaderCode('pixelIndex')};
    ${PROPERTIES_CASTED_A}
    ${HIGHLIGHT_INDEX_PROPERTY}
    ${VISIBLITY_PROPERTY}

    if (!visible) {
        // Good for debugging if a texture fails for some reason
        // if (int(colorPixelIndex) % textureSize.x == 0 && diffuseColor == vec4(0, 0, 0, 0)) {
        //     glFragColor = vec4(1, 0, 0, 1);
        //     return;
        // }
        discard;
    }

    if (highlighted) {
        finalDiffuse = ${HIGHLIGHT_COLORS}[highlightIndex - 1];
    }
    
    ${clipPlanesStrings.CLIP_PLANE_COLOR_BLENDING}

    #ifdef ${WEBGL2}
        glFragColor = vec4(finalDiffuse, opaqueness);
    #else
        gl_FragColor = vec4(finalDiffuse, opaqueness);
    #endif
}`;

export class IfcMaterial extends DitheringMaterial {
    private static readonly _tmp = {
        vectorA: Vector3.Zero(),
        colorA: Color3.White(),
        matrixA: Matrix.Identity()
    };

    constructor(
        name: string,
        scene: Scene,
        public readonly diffuseTexture: LazyResizableRawTexture,
        public readonly propertiesTexture: LazyResizableRawTexture,
        public readonly ditherTexture: DitherTexture
    ) {
        super(
            name,
            scene,
            {
                attributes: ['position', 'uv'],
                samplers: []
            },
            ditherTexture,
            true,
            false
        );

        this._isDitherModeEnabled = true;
        this.visualSettings.ifc.color.onPropertyChanged.add(() => {
            this.isDirty = true;
        });
    }

    getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const defines: string[] = [IOS_BIT_OPERATIONS, 'DITHERING_OPACITY_COEFFICIENT 1.0 / 3.0'];
        const uniforms = [
            'worldViewProjection',
            SIZE,
            PACK_SIZE,
            WORLD_MATRIX,
            EYE_POSITION_WORLD_SPACE,
            AMBIENT_COLOR,
            GROUND_COLOR,
            MATERIAL_DIFFUSE_COLOR,
            HEMISPHERIC_LIGHT_WORLD_SPACE_DIRECTION,
            HEMISPHERIC_LIGHT_COLOR,
            HEMISPHERIC_LIGHT_STRENGTH,
            DIRECTIONAL_LIGHT_COLOR,
            DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION,
            DIRECTIONAL_LIGHT_STRENGTH,
            DIRECTIONAL_LIGHT_LAMBERT_STRENGTH,
            DIRECTIONAL_LIGHT_SPECULAR_STRENGTH,
            DIRECTIONAL_LIGHT_DEPTH_VALUES,
            CAMERA_POSITION_WORLD_SPACE
        ];

        const samplers = [DIFFUSE_SAMPLER, PROPERTIES_SAMPLER];

        // Babylonjs need to have every index of uniform arrays addded to the list of uniforms used by the shader
        for (let i = 0; i < 3; i++) {
            uniforms.push(HIGHLIGHT_COLORS + '[' + i + ']');
        }

        const uniformsAndSamplers = super.getUniformsAndDefinesAndSamplers();

        const aggregatedUniformsAndSamplers = {
            uniforms: [...uniformsAndSamplers.uniforms, ...uniforms],
            defines: [...uniformsAndSamplers.defines, ...defines],
            samplers: [...uniformsAndSamplers.samplers, ...samplers]
        };

        this.visualSettings.ifc.color._insertUniformsAndDefines(aggregatedUniformsAndSamplers);

        return aggregatedUniformsAndSamplers;
    }

    override bindFunction(mesh: AbstractMesh): void {
        super.bindFunction(mesh);
        const scene = this.getScene();
        const camera = scene.activeCamera;
        if (!camera) {
            throw new Error('Active scene with no active camera?');
        }
        const effect = this.getEffect();
        effect.setTexture(PROPERTIES_SAMPLER, this.propertiesTexture.getTexture(mesh.getScene()));
        effect.setTexture(DIFFUSE_SAMPLER, this.diffuseTexture.getTexture(mesh.getScene()));

        effect.setFloat(PACK_SIZE, packedNormalPackSize);

        // The shader calculates normals in the fragment shader. To do so it is passed
        // the world position of the vertex. If the world positions are very large then
        // the precision of calculated the normals will suffer. To avoid we
        // subtract the camera position from the worldMatrix. That way
        // if the camera is close the the objects we get "small" coordinates in the calculated position
        // values and therefore the normal calculations do not get precision issues.
        // When calculating the normals it does not matter that its not the "real" world
        // coordinates.
        const wm = IfcMaterial._tmp.matrixA.copyFrom(mesh.getWorldMatrix());
        const { x, y, z } = camera.position;
        wm.addTranslationFromFloats(-x, -y, -z);

        effect.setMatrix(WORLD_MATRIX, wm);
        effect.setVector3(EYE_POSITION_WORLD_SPACE, camera.position);

        this.setColor4(MATERIAL_DIFFUSE_COLOR, MATERIAL_DIFFUSE_COLOR_DEFAULT_VALUE);

        effect.setColor3(AMBIENT_COLOR, this.visualSettings.ifc.lighting.ambient);

        const hemisphericLight = this.visualSettings.lighting.hemisphericLight;
        const directionalLight = this.visualSettings.lighting.directionalLight;

        const worldSpaceDirectionalLightDirection = directionalLight.direction;

        const groundColor = IfcMaterial._tmp.colorA
            .copyFrom(hemisphericLight.groundColor)
            .scale(hemisphericLight.intensity); // Since Color3.scale is inplace op we must copy the light color first.

        effect.setColor3(GROUND_COLOR, groundColor);
        effect.setColor3(HEMISPHERIC_LIGHT_COLOR, hemisphericLight.diffuse);
        effect.setVector3(HEMISPHERIC_LIGHT_WORLD_SPACE_DIRECTION, hemisphericLight.direction);
        effect.setVector3(CAMERA_POSITION_WORLD_SPACE, camera.position);
        effect.setVector2(
            RESOLUTION,
            new Vector2(scene.getEngine().getRenderWidth(), scene.getEngine().getRenderHeight())
        );

        effect.setColor3(DIRECTIONAL_LIGHT_COLOR, directionalLight.diffuse);
        effect.setFloat(DIRECTIONAL_LIGHT_STRENGTH, this.visualSettings.ifc.lighting.directionalLightStrength);
        effect.setFloat(
            DIRECTIONAL_LIGHT_LAMBERT_STRENGTH,
            this.visualSettings.ifc.lighting.directionalLightLambertStrength
        );
        effect.setFloat(
            DIRECTIONAL_LIGHT_SPECULAR_STRENGTH,
            this.visualSettings.ifc.lighting.directionalLightSpecularStrength
        );
        effect.setFloat(HEMISPHERIC_LIGHT_STRENGTH, this.visualSettings.ifc.lighting.hemisphericLightStrength);
        effect.setVector3(DIRECTIONAL_LIGHT_WORLD_SPACE_DIRECTION, worldSpaceDirectionalLightDirection);

        this.visualSettings.ifc.color._setEffectValues(effect);
        this.visualSettings.ifc._setEffectValues(effect);
    }

    public getFragmentSource(): string {
        return IFCMaterialFragmentShader;
    }

    public getVertexSource(): string {
        return IFCMaterialVertexShader;
    }
}
