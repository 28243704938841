import { AbstractMesh, Scene } from '../babylonjs-import';
import { CullingMaterial, UniformsAndDefinesAndSamplers, clipPlanesStrings } from './CullingMaterial';
import {
    BIT_SHIFTING,
    COLOR_PIXEL_INDEX,
    createPropertiesTextureReadShaderCode,
    DIFFUSE_SAMPLER,
    OUTLINE_ENABLED_PROPERTY,
    PROPERTIES_CASTED_A,
    PROPERTIES_SAMPLER,
    REQUIRES_WORLD_FRAGMENT,
    VISIBLITY_PROPERTY,
    WEBGL2
} from '../ShaderSnippets';
import { TextureObject } from '../texture-object';
import { LazyResizableRawTexture } from '../LazyResizableRawTexture';

const SIZE = 'size';
const WORLD_MATRIX = 'worldMatrix';
const IS_IFC_PICKING_MATERIAL = 'isIFcPickingMaterial';
const IS_OUTLINE_DISCERNING_MATERIAL = 'isOutlineDiscerningMaterial';
const READ_ALPHA_PROPERTY_BYTE = 'readAlphaPropertyByte';

// TODO: Simply have a custom shader for non IFC objects and everything will
//       be much easier to understand and maintain. No more funky bit operations to detect
//       if a vertex/fragment belongs to an IFC object or not.

//CREATE CUSTOM SHADER
const gpuCustomPickVertexShader = /*glsl*/ `
const float isNotIfcProductFlag = ${TextureObject.IsNotIfcProductFlag}.0;

#ifdef GL_ES
    precision highp float;
#endif
attribute vec3 position;
attribute vec2 uv;
uniform vec3 size;
uniform mat4 worldViewProjection;
uniform mat4 ${WORLD_MATRIX};
#ifdef ${WEBGL2}
    out vec3 vPositionW;
    out float vColorPixelIndex;
#else
    varying vec3 vPositionW;
    varying float vColorPixelIndex;
#endif

${BIT_SHIFTING}

void main(void) {
    gl_Position = worldViewProjection * vec4(position, 1.0);

    vPositionW = (${WORLD_MATRIX} * vec4(position, 1.0)).xyz;

    // uv.x is colorPixelIndex which represents 
    // * pixel offset in IFC object color texture (diffuseSampler). We calculate real UV coordinates from this
    // * gpu pickingcolor (RGB). We use this as fragment color to ge unique color representing the object this vertex belongs to.
    //   do note that we only use 24 bits of uv.x for color (alpha is not used). Hence we have a maximum of 2^24 unique colors.

    vColorPixelIndex = uv.x;
}    
`;

const gpuCustomPickFragmentShader = /*glsl*/ `
#ifdef GL_ES
    precision highp float;
#endif
#ifdef ${WEBGL2}
    in vec3 vPositionW;
    in float vColorPixelIndex;
#else
    varying vec3 vPositionW;    
    varying float vColorPixelIndex;
#endif
uniform vec3 size;
#ifdef ${READ_ALPHA_PROPERTY_BYTE}
    uniform sampler2D ${PROPERTIES_SAMPLER};
    ${clipPlanesStrings.CLIP_PLANE_UNIFORMS}
#endif
uniform sampler2D ${DIFFUSE_SAMPLER};

${BIT_SHIFTING}

vec3 unpackRGBColor(float f)
{
    vec3 color;
    f = floor(f);
    color.b = floor(f * rightShift16); // [A, B, G, R]
    float k = f - color.b * leftShift16; // [0, 0, B, 0]
    color.g = floor(k * rightShift8); // [R,G,0,0] => [G, 0, 0, 0]
    color.r = floor(k - color.g * leftShift8); 

    return color / 255.0;
}

void main(void) {
    ivec2 textureSize = textureSize(${DIFFUSE_SAMPLER}, 0);
    
    ${COLOR_PIXEL_INDEX}
    
    // colorPixelIndex holds the gpu picking color 
    vec3 gpuPickingColorAndAlpha = unpackRGBColor(colorPixelIndex);  
    #ifdef ${REQUIRES_WORLD_FRAGMENT}
        vec3 worldSpaceVertex = vPositionW;
    #endif
    
    #ifdef ${READ_ALPHA_PROPERTY_BYTE}
        ${createPropertiesTextureReadShaderCode('pixelIndex')};
        ${PROPERTIES_CASTED_A}
    #endif 
    #ifdef ${IS_IFC_PICKING_MATERIAL}
        ${clipPlanesStrings.CLIP_PLANE_DISCARDING}
        
        ${VISIBLITY_PROPERTY}

        if (!visible) {
            discard;
        }

        float alpha = texelFetch(diffuseSampler, ivec2(pixelIndex), 0).a;
        if (sign(alpha) < 1.0) {
            discard;
        }
    #endif
    #ifdef ${IS_OUTLINE_DISCERNING_MATERIAL}
        ${OUTLINE_ENABLED_PROPERTY}
        if (!outlined) {
            discard;
        }
    #endif
    
    
    gl_FragColor = vec4(gpuPickingColorAndAlpha, 1.0);
}
`;

export class GpuPickingMaterial extends CullingMaterial {
    constructor(
        name: string,
        scene: Scene,
        public readonly diffuseTexture: LazyResizableRawTexture,
        public readonly propertiesTexture?: LazyResizableRawTexture,
        public readonly isOutlineDiscerner: boolean = false
    ) {
        super(
            name,
            scene,
            {
                attributes: ['position', 'uv'],
                samplers: []
            },
            false,
            false
        );

        this.visualSettings.ifc.transparency.onPropertyChanged.add((change) => {
            if (change.property === 'ditheringOptions') {
                this.isDirty = true;
            }
        });
    }

    public getFragmentSource(): string {
        return gpuCustomPickFragmentShader;
    }

    public getVertexSource(): string {
        return gpuCustomPickVertexShader;
    }

    public getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const uniformsAndDefinesAndSamplers = super.getUniformsAndDefinesAndSamplers();
        const uniforms = ['worldViewProjection', SIZE, WORLD_MATRIX];
        const defines: string[] = [];
        const samplers: string[] = [DIFFUSE_SAMPLER];

        if (this.propertiesTexture || this.isOutlineDiscerner) {
            defines.push(READ_ALPHA_PROPERTY_BYTE);
            samplers.push(PROPERTIES_SAMPLER);
        }

        if (this.propertiesTexture) {
            defines.push(IS_IFC_PICKING_MATERIAL);
        }

        if (this.isOutlineDiscerner) {
            defines.push(IS_OUTLINE_DISCERNING_MATERIAL);
        }

        return {
            uniforms: [...uniforms, ...uniformsAndDefinesAndSamplers.uniforms],
            defines: [...defines, ...uniformsAndDefinesAndSamplers.defines],
            samplers: [...uniformsAndDefinesAndSamplers.samplers, ...samplers]
        };
    }

    override bindFunction(mesh: AbstractMesh): void {
        super.bindFunction(mesh);
        const effect = this.getEffect();
        effect.setTexture(DIFFUSE_SAMPLER, this.diffuseTexture.getTexture(mesh.getScene()));
        if (this.propertiesTexture) {
            effect.setTexture(PROPERTIES_SAMPLER, this.propertiesTexture.getTexture(mesh.getScene()));
        }
        effect.setMatrix(WORLD_MATRIX, mesh.getWorldMatrix());
    }
}
