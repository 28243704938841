import { BimApi, BimApiLoadOptionsFactory, BimDevApiClient, BimTwinfinityApiClient } from "@twinfinity/core";


async function main() {
  alert("4.1.1");
  const devFiles = (await fetch("./dist/dev-files.json").then((r) => r.json()));
  const client = new BimDevApiClient(devFiles);
  const container = await client.getContainers();
  const api = await BimApi.create('viewer', client);
  await api.setContainer(container[0], BimApiLoadOptionsFactory.all());
  

  // await api.setContainer(new URL("https://bim.bit.projektstruktur.se/sites/portal/projects/HelloTwinfinityWorld"), BimApiLoadOptionsFactory.all());
};

main();